import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import axios from 'axios'; // 如果你还没有导入axios，需要先导入

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [giftCode, setGiftCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const topUp = async () => {
    if (redemptionCode === '') {
      showInfo('请输入充值码！')
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode,
        gift_code: giftCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('充值成功！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('请求失败');
    } finally {
      setIsSubmitting(false); 
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('超级管理员未设置充值链接！');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const openNewPage = () => {
    // 成本计算器
    const link = 'https://openkey.cloud/partnerplan';

    // 打开新页面并跳转链接
    window.open(link, '_blank');
  };

  // 在这里定义 openChat 函数
  const openChat = () => {
    window.chaport.q('open');
  };

  const getUserQuota = async ()=>{
    let res  = await API.get(`/api/user/self`);
    const {success, message, data} = res.data;
    if (success) {
      setUserQuota(data.quota);
    } else {
      showError(message);
    }
  }

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();
  }, []);

  return (
    <div>
      <Segment>
        <Header as='h3'>充值额度</Header>
        <Grid columns={2} stackable>
          <Grid.Column>
            <Form>
              <Form.Input
                placeholder='套餐兑换码（必填）'
                name='redemptionCode'
                value={redemptionCode}
                onChange={(e) => {
                  setRedemptionCode(e.target.value);
                }}
              />
              <Form.Input
                placeholder='额度赠送码（选填）'
                name='giftCode'
                value={giftCode}
                onChange={(e) => {
                  setGiftCode(e.target.value)
                }}
              />
              {/* <Button color='green' onClick={openTopUpLink}>
                Get Redeem Code
              </Button> */}
              <Button color='green' onClick={topUp} disabled={isSubmitting}>
                  {isSubmitting ? '兑换中...' : '兑换'}
              </Button>
              <Button style={{ backgroundColor: '#4FA586', color: 'white'  }} onClick={openNewPage}>
                获取赠送码（5% 额外赠送）
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Statistic.Group widths='one'>
              <Statistic>
                <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
                <Statistic.Label>剩余额度</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Grid.Column>
        </Grid>
      </Segment>

      {/* 一周年活动海报 */}
      <Segment>
        <Header as='h3'>关于我们</Header>
        {/* 通知 */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <div className="ui message" style={{ flex: '1 1 0%', marginRight: '5px' }}>
            OpenKey平台于2023年7月初构建完成，提供稳定的ChatGPT模型接口服务，已持续运营超12个月。更多商品：<a href="https://shop.51buygpt.net/" target='_blank'>https://shop.51buygpt.net</a><br /><br />
            2024年4月，我们推出了 OpenKey.Cloud 合伙人计划，双重返佣规则，最多可获得 10% 返佣比例。期待您的加入！
          </div>
          {/* <button className="ui small positive button">复制接口地址</button> */}
        </div>
      </Segment>
      
      {/* 新添加的代码 */}
      <Segment>
      <Header as='h3'>选购套餐</Header>
      {/* 通知 */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div className="ui message" style={{ flex: '1 1 0%', marginRight: '5px' }}>
          购买提示：选择一个套餐并点击购买，获得兑换码后，返回这里进行激活。额度<strong>有效期9个月</strong>，请按需购买。
        </div>
        {/* <button className="ui small positive button">复制接口地址</button> */}
      </div>
      {/* 卡片 1/2 */}
      <Grid columns={4} stackable>
        <Grid.Row>
          <Grid.Column>
            {/* 5$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $10.00 套餐兑换码
                </div>
                <div className="meta">6折优惠，点击购买！</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  {/* 价格: ￥4.00 <del>￥5.00</del> */}
                  价格: ￥6.00
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://pay.openkey.cloud/buy/2" target="_blank" rel="noopener noreferrer" className="ui blue button">购买</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 10$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $50.00 套餐兑换码
                </div>
                <div className="meta">6折优惠，点击购买！</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  价格: ￥30.00
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://pay.openkey.cloud/buy/4" target="_blank" rel="noopener noreferrer" className="ui blue button">购买</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 20$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $120.00 套餐兑换码
                </div>
                <div className="meta">5折优惠，点击购买！</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  价格: ￥60.00
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://pay.openkey.cloud/buy/5" target="_blank" rel="noopener noreferrer" className="ui blue button">购买</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 50$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $500.00 套餐兑换码
                </div>
                <div className="meta">5折优惠，点击购买！</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  价格: ￥250.00
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://pay.openkey.cloud/buy/6" target="_blank" rel="noopener noreferrer" className="ui blue button">购买</a>
              </div>
            </div>
            </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* 卡片 2/2 */}
      <Grid columns={4} stackable>
        <Grid.Row>
          <Grid.Column>
            {/* 120$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $1000.00 套餐兑换码
                </div>
                <div className="meta">5折优惠，点击购买！</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  价格: ￥500.00
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://pay.openkey.cloud/buy/7" target="_blank" rel="noopener noreferrer" className="ui blue button">购买</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 500$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  $2500.00 官方API账号
                </div>
                <div className="meta">官方75折，Tier2速率，质保7天，适合专业用户及企业用户。</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  价格: ￥5.40
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://shop.51buygpt.net/buy/70" target="_blank" rel="noopener noreferrer" className="ui blue button">查看</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 1000$ */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  发票、对公、合同相关
                </div>
                <div className="meta">添加微信，采购同学来对接</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  点击跳转
                </div>
                {/* <button className="ui blue button" onClick={() => console.log('bug $5.00 redeem code')}>Buy</button> */}
                <a href="https://terobox.notion.site/890a912423614b848f6d4a7c41bd98f0" target="_blank" rel="noopener noreferrer" className="ui blue button">阅读</a>
              </div>
            </div>
            </Grid.Column>
            <Grid.Column>
            {/* 私人定制 */}
            <div className="ui card"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px', border: '2px solid rgb(212, 212, 213)' }}>
              <div className="content">
                <div className="header" style={{ marginBottom: '10px' }}>
                  <i aria-hidden="true" className="credit card outline icon"></i>
                  定制套餐
                </div>
                <div className="meta">专业团队，快速启动你的项目</div>
                <div className="description" style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                  内部价格
                </div>
                {/* <a href="#" rel="noopener noreferrer" className="ui blue button" onclick="window.chaport.q('open'); return false;"> Contact Team</a> */}
                <a href="#" rel="noopener noreferrer" className="ui blue button" onClick={openChat}> 联系客服</a>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    </div>
  );
};

export default TopUp;