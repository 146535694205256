import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Divider,
  Header,
  Label,
  Segment,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableFooter,
  TableCell,
  TableBody,
  MenuItem,
  Icon,
  Menu,
  Table, Pagination
} from 'semantic-ui-react';
import { API, copy, showError, showSuccess, timestamp2string } from '../../helpers';
import { ITEMS_PER_PAGE } from '../../constants';
import { UserContext } from '../../context/User';


const ParnterPlanInfo = () => {
  const friendPageSize = 10;
  const [friends, setFriends] = useState([]);
  const [friendsActivePage, setFriendsActivePage] = useState(1);
  const [partnerPlanData, setPartnerPlanData] = useState({});
  const [isLoadingFriends, setIsLoadingFriends] = useState(true);

  const loadFriends = async (startIdx) => {
    setIsLoadingFriends(true)
    const res = await API.get(`/api/user/friends?page=${startIdx}&size=${friendPageSize}`);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setFriends(data);
      } else {
        let newFriends = friends;
        newFriends.push(...data);
        setFriends(newFriends);
      }
    } else {
      showError(message);
    }
    setIsLoadingFriends(false);
  }

  const onFriendsPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(friends.length / friendPageSize) + 1) {
        // In this case we have to load more data and then append them.
        await loadFriends(activePage - 1);
      }
      setFriendsActivePage(activePage);
    })();
  };
  const loadPartnerPlanData = async () => {
    const res = await API.get(`/api/user/partner_plan_info`);
    const { success, message, data } = res.data;
    if (success) {
      setPartnerPlanData({
        aff_link: `${window.location.origin}/register?aff=${data.aff_code}`,
        gift_code: data.gift_code,
        invite_balance: `￥${data.invite_balance}`
      });
      console.log(data)
    } else {
      showError(message);
    }
  };

  const copyAffLink = async () => {
    await copy(partnerPlanData.aff_link)
    showSuccess(`邀请链接已复制到剪切板`);
  }

  const copyGiftCode = async () => {
    await copy(partnerPlanData.gift_code)
    showSuccess(`赠送码已复制到剪切板`);
  }

  useEffect(() => {
    loadPartnerPlanData()
      .then()
      .catch((reason) => {
        showError(reason);
      });
  }, []);

  useEffect(() => {
    loadFriends(0)
      .then()
      .catch((reason) => {
        showError(reason)
      })
  }, []);
  return (
    <>
      <Header>邀请信息</Header>
      <Segment>
        <Button onClick={copyAffLink}>复制邀请链接</Button>
        <div>{partnerPlanData.aff_link}</div>
        <Button onClick={copyGiftCode}>复制赠送码</Button>
        <div>{partnerPlanData.gift_code}</div>
      </Segment>

      <Header>我的好友</Header>
      <Table celled>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>受邀时间</TableHeaderCell>
            <TableHeaderCell>用户名</TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {
            friends.slice(
              (friendsActivePage - 1)*friendPageSize,
              friendsActivePage * friendPageSize,
            ).map((friend, idx) => {
              return (
                <TableRow>
                  <TableCell>{timestamp2string(friend.invited_time)}</TableCell>
                  <TableCell>{friend.username}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableHeaderCell colSpan="2">
              <Pagination
                floated='right'
                activePage={friendsActivePage}
                onPageChange={onFriendsPaginationChange}
                size='small'
                siblingRange={1}
                totalPages={
                  Math.ceil(friends.length/friendPageSize) +
                  (friends.length % friendPageSize === 0 ? 1 : 0)
                }/>
            </TableHeaderCell>
          </TableRow>
        </TableFooter>
      </Table>
      <Divider />
      <Header>我的收入</Header>
      <Segment>
        <Segment>
          <p
            style={{
              backgroundColor: '#f0f0f0', // 浅灰色背景
              color: '#333333', // 深灰色文字
              padding: '10px 20px', // 与按钮相同的内边距
              borderRadius: '20px', // 圆角边框
              fontFamily: 'Helvetica, Arial, sans-serif', // 字体
              fontSize: '18px', // 字号
              fontWeight: 'bold', // 加粗
              textAlign: 'center', // 文字居中
              margin: '0 0 20px 0', // 底部外边距，与按钮之间留出空间
              display: 'inline-block', // 使其不占据整行
              width: 'auto', // 宽度自适应内容
              minWidth: '100px' // 最小宽度，保证美观
            }}
          >
            余额：{partnerPlanData.invite_balance}
          </p>
          <button
            onClick={() => window.chaport.q('open')}
            style={{
              backgroundColor: '#1ba784',
              color: '#ffffff',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '20px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontSize: '18px',
              fontWeight: 'bold',
              textDecoration: 'none'
            }}
          >
            申请提现
          </button>
        </Segment>

        <Segment>注意：满 ￥100 可申请提现，支持支付宝、USDT。网页右下角联系客服处理，预计审核时间24小时。如有问题，请联系我们。
        </Segment>
      </Segment>

    </>
  );
}

const PartnerPlan = () => {
  const [userState, userDispatch] = useContext(UserContext);
  return (
    <Segment style={{ lineHeight: '40px' }}>
      <Header>合伙人计划说明</Header>
      <Segment>
        <p>
          <strong>加入 OpenKey.Cloud 合伙人计划，双重返佣规则，最多可获得 10% 返佣比例！</strong><br /> <br />

          <strong>规则1. 基于邀请关系，返现金（可提现）</strong><br />
          用户A邀请B成功注册，B充值额度，则A可收到现金奖励 = B充值额度 x 0.5 x 5%（即最大 5% 返现）。<br /> <br />

          <strong>规则2. 基于额度赠送码，返额度（可平台内使用）</strong><br />
          用户B使用A的【额度赠送码】完成充值，则A用户和B用户可同时收到额度奖励 = B充值额度 x 5%（即双方各赠 5% 额度）。<br /><br />

          <strong>举例演示</strong><br />
          用户A分享邀请链接给用户B，B成功注册后，花费￥500元购买了一个$1000刀额度兑换码。<br />
          接着，用户B在兑换额度的时候，使用了A的【额度赠送码】。此时：<br /><br />

          1. 用户A将获得：现金奖励 = ￥500 x 5% = <strong>25元</strong>，同时获得赠送额度奖励 = $1,000 x 5% = <strong>50刀</strong>；<br />
          2. 用户B将获得：充值额度 $1000美金，同时获得赠送额度 = $1,000 x 5% = <strong>50刀</strong>。<br /><br />

          <strong>注意事项</strong><br />
          1. 两个返佣规则各自独立，可重叠生效；<br />
          2. 返佣收入明细可查看日志页面，选择【系统】标签进行筛选；<br />
          3. 仅建立邀请关系，而不填写【额度赠送码】，好友用户充值额度时，规则2不会生效；<br />
          4. 若A的邀请链接为：<a href="https://openkey.cloud/register?aff=OPENK" target='_blank'>https://openkey.cloud/register?aff=OPENK</a>，则【额度赠送码】对应邀请链接后五位为：<strong>OPENK</strong>；<br />
          5. 禁止邀请自身或进行任何形式的套利活动。我们将保留撤回已发放返佣奖励的权利，并在严重违规情况下，考虑停用相关账户；<br />
          6. 联系我们：网页右下角在线客服（12h x 7d）或 support@gptocean.com。<br /><br />
          <img src="https://terobox.oss-cn-hongkong.aliyuncs.com/openkey/partnerplan.png" alt="OpenKey.Cloud 合伙人计划 海报" width={600} />
        </p>
      </Segment>
      {userState.user ? (<ParnterPlanInfo></ParnterPlanInfo>) : (<></>)}
    </Segment>
  )
};

export default PartnerPlan;
