import React from 'react';

const Pricing = () => {
  return (
    <iframe
      src={"https://page.openkey.cloud/pricing.html"}
      style={{ width: '100%', height: '85vh', border: 'none' }}
    />
  );
};


export default Pricing;

// add a new page... 4/4