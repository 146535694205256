import React from 'react';

const Docs = () => {
  return (
    <iframe
      src={"https://docs.openkey.cloud/"}
      style={{ width: '100%', height: '85vh', border: 'none' }}
    />
  );
};


export default Docs;