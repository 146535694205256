import React from 'react';

const Cheap = () => {
  return (
    <iframe
      src={"https://docs.openkey.cloud/11"}
      style={{ width: '100%', height: '85vh', border: 'none' }}
    />
  );
};


export default Cheap;

// add a new page... 4/4